<!-- 学禅典籍 -->
<template>
	
		<div class="word">
			<div class="bookItem mb20" v-for="(item,index) in contentList" :key="index" style="width:100%;">
				<h3>{{item.cate_name}}</h3>
				<table border="1" cellspacing="0">
					<tr class="ta-l">
						<th>{{$t('itemNumber')}}</th>
						<th>{{$t('ancientBookName')}}</th>
						<th>{{$t('itemNumber')}}</th>
						<th>{{$t('ancientBookName')}}</th>
					</tr>
					<tr v-for="(item1,index1) in getLength(item.lists)" :key="index1">
						<td class="numTitle">{{index1+1}}</td>
						<td class="contentTitle">
							<div v-if="item.lists[index1]" @click="linkToDetail(item.lists[index1])">
								{{item.lists[index1].news_title}}</div>
						</td>
						<td class="numTitle">{{index1+getLength(item.lists)+1}}</td>
						<td class="contentTitle">
							<div v-if="item.lists[index1+getLength(item.lists)]"
								@click="linkToDetail(item.lists[index1+getLength(item.lists)])">
								{{item.lists[index1+getLength(item.lists)].news_title}}</div>
						</td>
					</tr>
				</table>
			</div>
			<h3 style="font-weight:400">{{$t('note1')}}</h3>
		</div>


</template>

<script>
	import {
		getCateList,
		getAncientBooksList
	} from "../../api/apis";
	export default {
		name: "",

		data() {
			return {
				contentList: []
			};
		},

		methods: {
			//文章详情
			linkToDetail(item) {
				window.open(this.$BaseURL + '/articleDetail?name=ancientBooks&id=' + item.id);
				// this.$router.push({
				//     path: "/articleDetail",
				//     query: { name: "ancientBooks", id: item.id }
				// });
			},
			getLength(arr) {
				return Math.ceil(arr.length / 2);
			},
			//初始化数据
			initData() {
				let list = this.contentList;
				// this.length = Math.ceil(list.length / 2);
				for (let i = 0; i < list.length; i++) {
					if (list[i].lists) {
						if (list[i].lists.length % 2 === 1) {
							list[i].lists[list[i].lists.length] = {};
						}
					} else {
						list[i].lists = [];
					}
				}
			}
		},
		created() {
			// this.initData();
			getCateList().then(res => {
				let id = res[1][7].id;
				getAncientBooksList(id).then(res => {
					this.contentList = res;
					this.initData();
				});
			});
		}
	};
</script>

<style lang="stylus" scoped>
	h3 {
		line-height: 30px;
		text-align: left;
	}

	.ta-l {
		text-align: left;
	}

	.pl5 {
		padding-left: 5px;
	}

	.word {
		width: 750px;
		margin: 10px 10px;

		.title {
			font-size: 15px;
			// font-weight: 600;
		}
	}

	table,
	td,
	tr,
	th {
		border: 1px solid #ACACAC;
	}

	table {
		width: 100%;
		font-size: 15px;
		text-align: left;

		th {
			line-height: 30px;
			padding-left: 5px;
			font-weight: 400;

			.title {
				margin-top: 5px;
				// font-weight: 600;
			}

			.brief {
				font-weight: 400;
				font-size: 14px;
			}
		}

		td {
			line-height: 30px;
			padding-left: 5px;
		}

		td.contentTitle:hover {
			color: #1482d9;
			cursor: pointer;
		}

		.numTitle {
			width: 60px;
		}

		.contentTitle {
			width: 378px;
			padding-left: 5px;
			text-align: left;
		}
	}
</style>